.show-on-hover {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-out;
  -moz-transition: opacity 0.15s ease-out;
  -o-transition: opacity 0.15s ease-out;
  -ms-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

.show-on-hover:hover {
  opacity: 1;
  -webkit-transition: opacity 0.15s ease-in;
  -moz-transition: opacity 0.15s ease-in;
  -o-transition: opacity 0.15s ease-in;
  -ms-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
}

.highlight-on-hover:hover {
  background-color: #eee;
}

.no-border > input {
  border: 0 !important;
  border-bottom: 1px solid #aaa !important;
  border-radius: 0 !important;
}

.item-header,
.item-header input {
  margin: 0px 0px 12px 0px;
  padding: 0px;
  border: 0px !important;
  border-bottom: 1px solid #ccc !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.item-row {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 4px !important;
}

.ql-editor {
  min-height: 140px;
}
