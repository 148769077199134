.chrome-picker {
  margin-top: 12px;
  margin-bottom: -8px;
  box-shadow: none !important;
}

.chrome-picker > div:last-child > div > div:first-child,
.chrome-picker > div:last-child > div > div:last-child > div:last-child,
.chrome-picker span {
  display: none !important;
}

.hue-horizontal {
  height: 24px !important;
}

.hue-horizontal > div:last-child > div {
  width: 26px !important;
  height: 26px !important;
  border-radius: 13px !important;
}