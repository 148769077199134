body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.fade-in {
  animation: fadein 0.6s;
  -moz-animation: fadein 0.6s; /* Firefox */
  -webkit-animation: fadein 0.6s; /* Safari and Chrome */
  -o-animation: fadein 0.6s; /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.staff span {
  position: absolute;
}

.ui.selection.active.dropdown .menu {
  max-height: 520px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
}

h1 {
  font-size: 16pt;
}

h2 {
  font-size: 14pt;
}
