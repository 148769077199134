.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #333 !important;
  border: 0;
}

.DayPickerKeyboardShortcuts_show {
  display: none;
}

#start-date {
  width: 120px;
}

#end-date {
  width: 120px;
}

.DateRangePickerInput_arrow {
  margin-left: 10px;
  margin-right: 14px;
}
