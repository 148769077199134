.ql-editor {
  border: 1px solid #ddd;
  border-radius: 3px;
  min-height: 108px;
}

.ql-editor p,
.ql-editor li,
.ql-editor ol {
  font-size: 11pt;
}
